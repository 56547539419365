import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';


class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className='heading-h3'><span className="bluecolor">Mind-Blowing Benefits</span> of</span> White Label Cryptocurrency Exchange
              </h3>
              <p className="pharagraph head">There are numerous benefits to using white label cryptocurrency exchange software to launch your cryptocurrency business. With our premier white label solutions, you can effortlessly unlock a range of advantages designed to set you on the path to success.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-8 col-md-12 text-center" >
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/why-should-whitelabel-crypto-exchange.png"
                      alt="Mind-Blowing Benefits of White Label Cryptocurrency Exchange Banner"
                      width={630}
                    />
            </div>
            <div className="col-lg-4 col-md-12">
              <ul>
                <li className="pharagraph">Highly customizable </li>
                <li className="pharagraph" >Bug-free & secure
                </li>
                <li className="pharagraph" >Cost-effective</li>
                <li className="pharagraph" >Faster deployment </li>
                <li className="pharagraph" >Easy to use platform</li>
                <li className="pharagraph" >No need for technical expertise </li>
                <li className="pharagraph" >High ROI</li>
                <li className="pharagraph" >Eye-catching and user-friendly dashboard </li>
              </ul> 
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould