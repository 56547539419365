import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'


class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className='heading-h3'><span className="bluecolor">Top Features</span> Of Our</span>
               White Label Cryptocurrency Exchange Development</h3>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">KYC/AML</h4>
                <p className="pharagraph">Our crypto exchange white label software development comes with the KYC/AML feature where your users have to submit their identity documents for verification.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/kyc-verification.png"
              alt="KYC/AML"
              width={555}
            />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/high-liquidity.png"
              alt="Advanced Trading Engine"
              width={537}
            />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Advanced Trading Engine</h4>
                <p className="pharagraph">Our upgraded white label bitcoin exchange software comes with a powerful Trading Engine that enables your users to match the buy and sell orders without any delay.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Multi-Cryptocurrency Wallet Integration</h4>
                <p className="pharagraph">The integrated cryptocurrency wallet helps your users to store, receive, and transfer any cryptocurrency assets in a hassle-free manner.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/multi-cryptocurrency-wallet-integration.png"
              alt="Multi-Cryptocurrency Wallet Integration"
              width={537}
            />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/high-transactions-per-second.png"
              alt="High Transactions per Second"
              width={537}
            />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">High Transactions Per Second</h4>
                <p className="pharagraph">Our best White Label Cryptocurrency exchange script provides an exemplary performance by handling several cryptocurrency coins or token transactions per second without any latency. </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Advanced Chart Tools
                </h4>
                <p className="pharagraph">By integrating an advanced trade chart, your users can get a smooth graphical trading experience that makes them stay active on the crypto white label exchange platform.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/whitelabel-new/advance-trading-engine.png"
              alt="Advanced Chart Tools"
              width={555}
            />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures