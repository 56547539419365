import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
      <div className="text-center">
          
          <h6 className="heading-h2">FAQ</h6>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                1. Is it possible to launch your cryptocurrency exchange within 4 weeks?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Absolutely! One of the key benefits of a White Label Crypto exchange software  is the significantly reduced development time. This streamlined approach allows you to launch your Cryptocurrency exchange within 4 weeks.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                2. How Can You Ensure the Security of Your Exchange Software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">We, at Coinsclone, have included the top-most security features like SQL injection prevention, Anti DOS, CSRF Protection, and SSRF Protection in our white label cryptocurrency exchange script to ensure the security of your crypto exchange software.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                3. What is the Cost of White Label Crypto Exchange Platform?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">White Label Cryptocurrency Exchange is budget-friendly! Starting at just $7,000, the cost can extend to $15,000 based on the customizations and requirements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                4. What are the Key Attributes of White-Label Crypto Exchange Solution?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Some key attributes of the White-Label Crypto Exchange solution include an Advanced Trading Engine, KYC system, Admin Profit management, Crypto and Fiat support, etc. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                5. What are the Revenue Factors of White Label Cryptocurrency Exchange Software?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Trading fees, listing fees, Crypto withdrawal fees, Fiat deposit fees, and IEO Launchpad are five basic revenue streams of White Label Crypto exchanges. You can customize the exchange for further revenue stream inclusions. 
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection